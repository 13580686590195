<template>
  <div class="forgot box">
    <h2 class="title is-2">{{ $t('account.forgot_password') }}</h2>
    <form @submit.prevent="forgot()">
      <b-input size="is-large" v-model="email" name="email" :placeholder="$t('account.email')"/><br/>
      <b-button size="is-large" class="is-primary" native-type="submit">{{ $t('account.reset_password') }}</b-button>
      <p v-if="success" class="has-text-success">{{ $t('account.reset_password_mail') }}</p>
      <p v-if="error" class="has-text-danger">{{ $t(errormsg) }}</p>
    </form>
  </div>
</template>

<script>
import i18n from '@/plugins/vuei18n.js';

export default {
  name: 'forgot',
  data(){
    return {
      error: false,
      success: false,
      errormsg: "",
      email: "",
    };
  },
  methods: {
    checkMail(){
      return (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email));
    },

    checkData(){
      this.errormsg = 'errors.account.';
      if(this.email == ""){
        this.errormsg += 'enter_email';
        this.error = true;
        return false;
      }
      if(!this.checkMail()){
        this.errormsg += 'invalid_email';
        this.error = true;
        return false;
      }
      this.errormsg = '';
      return true;
    },

    forgot() {
      this.error = false;
      if(this.checkData()){
        var data = { email: this.email, locale: i18n.locale };
        this.post('/forgot', data).then(data => {
          this.error = false;
          this.success = true;
        }).catch(err => {
          this.error = true;
          this.errormsg = err;
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.forgot{
  padding: 40px;
  max-width: 600px;
  margin: 40px auto;
}
</style>
